import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['carousel.component.css']
})
export class CarouselComponent {
  images = [];

  constructor() { }

  ngOnInit() {
    // portrait photos
    if (window.innerHeight > window.innerWidth) {
      this.images = ['./assets/images/love byrds cropped.jpg',
                './assets/images/graffiti.jpg',
                './assets/images/laughing.jpg',
                './assets/images/restaurant cropped.jpg',
                './assets/images/cheek kiss cropped.jpg'];
    } else {
      // landscape photos
      this.images = ['./assets/images/love byrds.jpg',
                './assets/images/flirting.jpg',
                './assets/images/cheek kiss.jpg',
                './assets/images/alright.jpg',
                './assets/images/restaurant.jpg'];
    }
  }
}
