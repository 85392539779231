import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RSVP } from '../models/RSVP';
import { ApiResponse } from '../models/ApiResponse';
import { RSVPService } from '../services/rsvp.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponseComponent } from '../dialog/response/response.component';
import { MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Guest } from '../models/Guest';




@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.css']
})
@Input('rsvp')
export class RSVPComponent implements OnInit {
  guest: FormGroup;
  partysize: number;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  responseMessage: string;
  header: string;
  body: string;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  guests: Guest[];
  temp: string[];

  constructor(fb: FormBuilder, public rsvpService: RSVPService, public dialog: MatDialog) {
    this.guest = fb.group({
      firstName: ['Phill', [Validators.required, Validators.maxLength(15), Validators.minLength(2)]],
      lastName: ['Byrd', [Validators.required, Validators.maxLength(15), Validators.minLength(2)]],
      attending: false,
      email: ['Phill@gmail.com', [Validators.required, Validators.email]],
      message: ['Congrats', Validators.maxLength(500)],
      numberInParty: [2],
      guests: [[]],
    });
    console.log('Form started: ', this.guest);

  }

  updateSetting(event) {
    this.partysize = event.value;
  }

  ngOnInit() {
    console.log('form loaded');
    this.partysize = 1;
    this.guests = [];
    this.guest.controls['guests'].setValue(this.guests);
    this.temp = [];
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.guest.controls[controlName].hasError(errorName);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.guests.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(guest: Guest): void {
    const index = this.guests.indexOf(guest);

    if (index >= 0) {
      this.guests.splice(index, 1);
    }
  }

  public onSubmitRSVP() {

    if (this.guest.valid) {
      if (this.guests !== null || this.guests.length > 0) {
        for (let value of this.guests) {
          this.temp.push(value.name);
        }
        this.guest.controls['guests'].setValue(this.temp);
      }
      this.executeGuestCreation(this.guest.value);
      this.guests = [];
      this.temp = [];
    } else {
      console.log('Here is the User RSVP data: ', this.guest.value);
    }
  }

  private executeGuestCreation = (guestFormValue) => {
    console.log('Here is the User RSVP data: ', guestFormValue);

    const guest: RSVP = {
      firstName: guestFormValue.firstName,
      lastName: guestFormValue.lastName,
      email: guestFormValue.email,
      attending: guestFormValue.attending,
      message: guestFormValue.message,
      numberInParty: guestFormValue.numberInParty,
      guests: guestFormValue.guests,
    };

    this.rsvpService.addGuest(guest).subscribe((response: ApiResponse) => {
      switch (response.code) {
        case 200:
          console.log('Successful Call :: API response: ' + response.response + ' :: Status Code: ' + response.code);
          this.header = 'We got your RSVP!';
          if (guest.attending === true) {
            this.body = 'We are looking forward to seeing you in November! If you have any questions, just email us at: (EMAIL)';
          } else {
            this.body = 'Thanks for letting us know! We are sorry to hear that you will not ' +
              'be seeing you in November! If you have any questions, email us at: (EMAIL)';
          }
          this.openDialog(guest, this.header, this.body);
          break;
        case 409:
          this.header = 'Uh oh! We already got your RSVP';
          this.body = 'Looks like we already have a record for the RSVP below. ' +
            'If you think that there is an error or have any questions, email us at: (EMAIL)';
          console.log('Successful Call :: API response: ' + response.response + ' :: Status Code: ' + response.code);
          this.openDialog(guest, this.header, this.body);
          break;
        default:
          this.header = 'We got your RSVP!';
          if (guest.attending === true) {
            this.body = 'We are looking forward to seeing you in November! If you have any questions, just email us at: (EMAIL)';
          } else {
            this.body = 'Thanks for letting us know! We are sorry to hear that you will not ' +
              'be seeing you in November! If you have any questions, email us at: (EMAIL)';
          }
          console.log('API response: ' + response.response + ' :: Status Code: ' + response.code);
          this.openDialog(guest, this.header, this.body);
          break;
      }
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
          console.log('There was an error detected on the API response: ' + error.message + ' :: Status Code: ' + error.status);
          this.header = 'Uh oh! Looks like there was an error with your RSVP!';
          this.body = 'Please try again and if you still have an issue, send us an email at: (EMAIL)';
          this.openDialog(guest, this.header, this.body);
          break;
        case 403:
          console.log('There was an error detected on the API response: ' + error.message + ' :: Status Code: ' + error.status);
          this.header = 'Uh oh! Looks like there was an error with your RSVP!';
          this.body = 'Please try again and if you still have an issue, send us an email at: (EMAIL)';
          this.openDialog(guest, this.header, this.body);
          break;
        case 404:
          console.log('There was an error detected on the API response: ' + error.message + ' :: Status Code: ' + error.status);
          this.header = 'Uh oh! Looks like there was an error with your RSVP!';
          this.body = 'Please try again and if you still have an issue, send us an email at: (EMAIL)';
          this.openDialog(guest, this.header, this.body);
          break;
        default:
          console.log('There was an UNKNOWN error detected on the API response: ' + error.message + ' :: Status Code: ' + error.status);
          this.header = 'Uh oh! Looks like there was an error with your RSVP!';
          this.body = 'Please try again and if you still have an issue, send us an email at: (EMAIL)';
          this.openDialog(guest, this.header, this.body);
          break;
      }
    });
  }

  public openDialog(processedRSVP: RSVP, header: string, body: string) {
    const dialogRef = this.dialog.open(ResponseComponent, {
      data: {
        header: header,
        body: body,
        processedRSVP: processedRSVP
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

