import { Component, HostListener, OnInit } from '@angular/core';
import { PausableObservable, pausable } from 'rxjs-pausable';
import { Subject, interval } from 'rxjs';
import { faHeart as farHeart, faGrinWink as farGrin } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library for convenient access in other components
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

import 'hammerjs';
import { MatDialog } from '@angular/material';
import { EmergencyComponent } from './dialog/emergency/emergency.component';
import { ResponseComponent } from './dialog/response/response.component';

declare var ConfettiGenerator: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Celebrating The Byrds';
  name = 'Angular';
  paused = true;
  pausable: PausableObservable<number>;

  engagementGalleryOptions: NgxGalleryOptions[];
  engagementGalleryImages: NgxGalleryImage[];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(library: FaIconLibrary, public dialog: MatDialog) {
    // Add multiple icons to the library
    library.addIcons(fasHeart, farHeart, farGrin);
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    var nc = document.getElementById("navbar-container");
    if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
      nc.className = "navbar-container animate-top";
    } else {
      nc.className = "navbar-container top";
    }
  }

  ngOnInit() {
    var cc = document.getElementById("content-container");
    
    var confettiSettings = {
      target: 'confetti-canvas',
      size: '1',
      rotate: true,
      clock: '10',
      max: '500',
      height: (cc.offsetHeight + (3 * window.innerHeight))
    };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    this.pausable = interval(75).pipe(pausable()) as PausableObservable<number>;
    this.pausable.subscribe(this.shoot.bind(this));
    this.pausable.pause();

    this.engagementGalleryOptions = [
      {
        width: '100%',
        height: '100vh',
        thumbnails: false,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSwipe: false,
        imageAutoPlay: true,
        imageAutoPlayInterval: 8000,
        preview: false,
        imageArrows: false,
      },
      // max-width 450
      {
        breakpoint: 450,
        imageSwipe: true
      }
    ];


    // portrait photos
    if (window.innerHeight > window.innerWidth) {
      this.engagementGalleryImages = [
        {
          medium: 'assets/images/love byrds cropped.jpg'
        },
        {
          medium: 'assets/images/graffiti.jpg'
        },
        {
          medium: 'assets/images/laughing.jpg'
        },
        {
          medium: 'assets/images/restaurant cropped.jpg'
        },
        {
          medium: 'assets/images/cheek kiss cropped.jpg'
        }
      ];

    } else {
      // landscape photos
      this.engagementGalleryImages = [
        {
          medium: 'assets/images/love byrds.jpg'
        },
        {
          medium: 'assets/images/flirting.jpg'
        },
        {
          medium: 'assets/images/cheek kiss.jpg'
        },
        {
          medium: 'assets/images/alright.jpg'
        },
        {
          medium: 'assets/images/restaurant.jpg'
        }
      ];
    }
    this.openDialog();
  }

  public openDialog() {
    const dialogRef = this.dialog.open(EmergencyComponent);
  }

  toggle() {
    console.log("toggle")
    if (this.paused) {
      this.pausable.resume();
    } else {
      this.pausable.pause();
    }
    this.paused = !this.paused;
  }

  shoot() {
    try {
      console.log("shoot")
      const colors = ['#F5DC9F', '#AD85EE', '#DC78A2', '#83D0E4', '#383396', '#FFFFFF'];

      this.confetti({
        particleCount: 6,
        angle: 75,
        spread: 55,
        origin: {
          x: 0,
          y: .75
        },
        colors: colors
      });
      this.confetti({
        particleCount: 6,
        angle: 105,
        spread: 55,
        origin: {
          x: 1,
          y: .75
        },
        colors: colors
      });
    } catch (e) {
      // noop, confettijs may not be loaded yet
    }
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  confetti(args: any) {
    return window['confetti'].apply(this, arguments);
  }
}
