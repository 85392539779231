import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RSVP } from '../models/RSVP';

@Injectable({
  providedIn: 'root'
})
export class RSVPService {

  constructor(private http: HttpClient) { }

  addGuest(rsvp: RSVP) {
    console.log('Made it to service ready to user to API Gateway', rsvp);
    // console.log(environment.gateway + 'guestRSVP', guest);

    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': '9gTt7vXVmI9bZeFzx73oRaHy0Oiy36GC7q7ZfcF2'
      })
    };

    const guestJSON = JSON.stringify(rsvp);


    return this.http.post('https://k198czole3.execute-api.us-east-2.amazonaws.com/dev/submitrsvp', guestJSON, HttpOptions);
  }
}
